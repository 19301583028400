import 'antd-mobile/es/global';
// 导入样式
import '@tea/component/tea.css';
// 导入style
import './styles/app.less';
import 'tdesign-react/es/style/index.css';

import './utils/storage';
import './styles/tailwind.css';
import './styles/mail.less';
import './styles/reset-onetrust.less';
import './styles/tdesign-reset.less';
import './styles/map.less';
import './styles/tea-reset.less';
// 导入依赖
import { RouteItem, app } from '@tea/app';
import { I18NInstance, init } from '@tea/app/i18n';

import 'regenerator-runtime/runtime.js';
import { lazy } from 'react';
import { lg } from './utils/language';
import { isCarbon } from './utils/oneTrust';
import { removeBasenameFromPathname } from './utils/Tools';
import { ConsoleRoute, FrontWeb, SINGLE_PAGE } from './routers';
import { qsParse } from './utils/common';
const ILayout = lazy(() => import('./components/ui-components/Layout2/Layout'));

// 路由代码
type IRoute = RouteItem & {
  title?: string;
  titleEn?: string;
};
const carbon = isCarbon();
// eslint-disable-next-line @typescript-eslint/naming-convention
const routes: IRoute[] = [
  // 首页
  !carbon && { path: '', component: lazy(() => import(/* webpackChunkName: "home" */ '@src/routes/home')) },
  FrontWeb,
  ConsoleRoute,
  ...SINGLE_PAGE,
  {
    path: '*',
    component: lazy(() => import(/* webpackChunkName: "home" */ '@src/routes/home')),
  },
];

const lang: 'zh' | 'en' = lg.getLang();

function loadWeiXinScript() {
  // 判断是否在微信中
  const isWeixin = /MicroMessenger/i.test(navigator.userAgent);

  if (isWeixin) {
    // 动态加载微信的jweixin-1.6.0.js脚本
    const script = document.createElement('script');
    script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
    script.onload = () => {
      console.log('Weixin script loaded');
      // 可以在这里调用微信JS-SDK的初始化代码
    };
    script.onerror = () => {
      console.error('Failed to load the Weixin script');
    };
    document.head.appendChild(script);
  } else {
    console.log('Not in Weixin, no need to load the script.');
  }
}

function loadShareScript() {
  const { from, type } = qsParse();
  // 来自小程序
  const isMiniProgram = Number(type) === 1 && !!from;
  if (!isMiniProgram) {
    // 动态加载微信的jweixin-1.6.0.js脚本
    const script = document.createElement('script');
    script.src = 'https://static.addtoany.com/menu/page.js';
    script.onload = () => {
      console.log('Weixin script loaded');
      // 可以在这里调用微信JS-SDK的初始化代码
    };
    script.onerror = () => {
      console.error('Failed to load the Weixin script');
    };
    document.head.appendChild(script);
  } else {
    console.log('Not in Weixin, no need to load the script.');
  }
}

const initCarbonLink = () => {
  replaceUrl();
  document.documentElement.lang = lang;
  const carbon = isCarbon();
  const baseName = lang === 'zh' && !carbon ? '/zh' : '';
  let resources: any = {};
  
  window.requestAnimationFrame(() => {
    loadWeiXinScript();
    loadShareScript();
  });

  if (lang === 'en') {
    // 英文时，获取英文翻译词条, 等翻译词条下载完后，再初始化页面
    import(/* webpackChunkName: "lang" */ '../i18n/translation/en').then((en) => {
      resources = { en: en.translation };
      initLayout(resources, baseName);
    });
    return;
  }
  initLayout(resources, baseName);


};

function initLayout(resources, baseName) {
  // 初始化词条
  init(resources, () => {
    I18NInstance.changeLanguage(lang);
  });
  // 初始化应用
  app.routes(
    routes.filter((v) => v),
    ILayout as any,
    baseName,
  );
}

// 立即执行
initCarbonLink();

// 路由判断
function replaceUrl() {
  // 动态添加路由
  const { pathname, search } = window.location;
  if (pathname.startsWith('/zh/zh/')) {
    const pathname = removeBasenameFromPathname();
    location.href = `${window.location.origin}${pathname}${search}`;
  }
  const carbon = isCarbon();
  if (pathname.startsWith('/zh') && carbon) {
    const pathname = removeBasenameFromPathname();
    location.href = `${window.location.origin}${pathname}${search}`;
  }
  if (!pathname.startsWith('/zh') && lg.isChinese && !carbon) {
    // 生成新的URL
    const newPathname = `/zh${window.location.pathname === '/' ? '' : window.location.pathname}`;
    // 仅修改浏览器地址栏URL，不重新刷新页面
    window.history.replaceState(null, '', `${window.location.origin}${newPathname}${window.location.search}`);
  }
}
