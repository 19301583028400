import qs from 'qs';
import moment from 'moment';
import { message } from '@tencent/tea-component';
import { t } from '@tea/app/i18n';
import { lg } from '@src/utils/language';
/**
 * 解析url后的值
 * @param search   非必须 需要解析的字符串
 * @returns object 对象
 */
export const qsParse = (search?: string): any => {
  if (!search || typeof search !== 'string') {
    search = window?.location?.search;
  }

  return qs?.parse(search, { ignoreQueryPrefix: true });
};

/**
 * 组装url后的值
 * @param params 必须 需要组装的对象
 * @returns string 字符串
 */
export const qsString = (params: { [index: string]: any }): string => {
  if (Object.prototype.toString.call(params) !== '[object Object]') {
    return '';
  }

  return qs?.stringify?.(params);
};

/**
 * message success
 */
export const success = (string: string) => {
  message.success({
    content: string,
  });
};

/**
 * message warning
 */
export const warning = (string: string) => {
  message.warning({
    content: string,
  });
};

/**
 * message fail
 */
export const fail = (string: string) => {
  message.error({
    content: string,
  });
};

/**
 * askQuestion
 */
// interface IProps {
//   message?: string;
//   description?: string;
//   okText?: string;
//   cancelText?: string;
//   api: string;
//   payload?: { [index: string]: any };
//   onCancel?: () => void;
//   onOk?: (data?: any) => any;
// }

// 密码校验规则，提示：
export const PASSWORD_TIPS_TEXT = t('至少包含1个大写字母和1个数字，符号可以使用_+-&=!@#$%^&*()，6-16个字符');
/**
 * 统一用户密码校验
 * 规则2: 至少包含1个大写字母和1个数字，符号可以使用_+-&=!@#$%^&*()，6-16个字符，
 */
export const checkPassword = (password: string): boolean => {
  if (password?.length < 6) {
    return false;
  }
  if (password?.length > 16) {
    return false;
  }
  const pArr = password?.toString().split('');
  const str1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const str2 = 'abcdefghijklmnopqrstuvwxyz';
  const str3 = '_+-&=!@#$%^&*()';
  const str4 = '0123456789';
  let c1 = 0;
  let c2 = 0;
  let c3 = 0;
  let c4 = 0;
  for (const item of pArr) {
    str1.includes(item) && (c1 = c1 + 1);
    str2.includes(item) && (c2 = c2 + 1);
    str3.includes(item) && (c3 = c3 + 1);
    str4.includes(item) && (c4 = c4 + 1);
  }
  if (c1 === 0) {
    return false;
  }
  if (c4 === 0) {
    return false;
  }
  if (c1 + c2 + c3 + c4 !== password?.length) {
    return false;
  }
  return true;
};

/**
 * 自动生成密码
 */
export const getAutoPassword = () => {
  const getRandom = (str) => str[Math.floor(Math.random() * str?.length)];
  const str1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const str2 = 'abcdefghijklmnopqrstuvwxyz';
  const str3 = '_+-&=!@#$%^&*()';
  const str4 = '0123456789';
  const allStr = str1 + str2 + str3 + str4;
  let str = '';
  for (let i = 0; i < 16; i++) {
    if (i === 3) {
      str = str + getRandom(str1);
    } else if (i === 5) {
      str = str + getRandom(str4);
    } else if (i === 8) {
      str = str + getRandom(allStr);
    } else if (i === 11) {
      str = str + getRandom(str1);
    } else if (i === 13) {
      str = str + getRandom(str4);
    } else {
      str = str + getRandom(allStr);
    }
  }
  return str;
};

/**
 * @param string 时间字符串，不传则是当前时间
 * @param format 格式，默认YYYY-MM-DD HH:mm:ss
 * @returns
 */
export const formatDate = (string, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (format === 'YYYY-MM-DD' && lg.isEnglish) {
    format = 'MMM DD, yyyy';
  }
  if (format === 'YYYY-MM-DD HH:mm:ss' && lg.isEnglish) {
    format = 'HH:mm:ss, MMM DD, yyyy';
  }
  return string ? moment(string).format?.(format) : string;
};

/**
 * @param string 判断的字符串
 * @param str 如果判断的字符串不存在，返回str，默认 '-'
 * @returns string
 */
export const renderNull = (string, str = '-') => string || str;

/**
 * tileArr
 */
export const tileArr = (data: any[]) => {
  const newarr = [];
  //
  const tile = (arr) => {
    arr?.forEach?.((i) => {
      newarr?.push(i);
      tile(i?.items);
    });
  };
  //
  tile(data);
  //
  return newarr;
};

/**
 * 可以在这里统一暴露方法，然后通过tool.方式调用，增加方法提示
 */
export const tool = {
  fail,
  success,
  warning,
  formatDate,
  renderNull,
  checkPassword,
  getAutoPassword,
  PASSWORD_TIPS_TEXT,
};
