import { lazy } from 'react';

// 用户前台 非首页
export const FrontWeb = {
  path: '/',
  component: lazy(() => import('@src/components/ui-components/Layout2/ContentLayout')),
  children: [
    // 创新团队
    {
      path: '/innovators',
      component: lazy(() => import('@src/routes/frontweb/innovation/innovation-team/InnovationTeam')),
    },
    {
      path: '/innovators/detail/:id',
      component: lazy(() => import('@src/routes/frontweb/innovation/innovation-team-detail')),
    },
    // 产品技术
    { path: '/solutions', component: lazy(() => import('@src/routes/frontweb/innovation/innovation-product')) },
    {
      path: '/solutions/detail/:id',
      component: lazy(() => import('@src/routes/frontweb/innovation/innovation-product-detail')),
    },
    // 资源星球
    { path: '/programs', component: lazy(() => import('@src/routes/frontweb/innovation/programs-list')) },
    { path: '/programs/detail/:id', component: lazy(() => import('@src/routes/frontweb/innovation/programs-detail')) },
    // 图谱
    { path: '/knowledge', component: lazy(() => import('@src/routes/frontweb/innovation/innovation-map')) },
    {
      path: '/knowledge/detail/:id',
      component: lazy(() => import('@src/routes/frontweb/innovation/innovation-map-detail')),
    },
    // 探寻计划
    { path: '/carbon', component: lazy(() => import('@src/routes/carbon')) },
    // 工具实验室
    { path: '/tools', component: lazy(() => import('@src/routes/frontweb/tools')) },
    { path: '/tools/detail/:id', component: lazy(() => import('@src/routes/frontweb/tools/ForPolicy')) },
    // 关于我们
    { path: '/about', component: lazy(() => import('@src/routes/frontweb/about')) },
    // 加入我们
    { path: '/creator', component: lazy(() => import('@src/routes/frontweb/about/Creator/Creator')) },
    // sitemap
    { path: '/sitemap', component: lazy(() => import('@src/routes/frontweb/sitemap')) },
    { path: '/help', component: lazy(() => import('@src/routes/frontweb/help-center/HelpCenter')) },
    // 邮箱验证
    { path: '/email-ver', component: lazy(() => import('@src/routes/frontweb/email-verify/VerifyIndex')) },
    // 其他
    { path: '/pdf2md', component: lazy(() => import('@src/routes/frontweb/pdf2md/Pdf2md')) },
    // { path: '/ai/mees', component: lazy(() => import('@src/routes/frontweb/chat')) },
    // { path: '/ai/tfoundation', component: lazy(() => import('@src/routes/frontweb/chat')) },
    // { path: '/ai/Unilumin', component: lazy(() => import('@src/routes/frontweb/chat')) },
    {
      path: '/@aipartners',
      component: lazy(() => import('@src/routes/single-page/AIAssistant/AIAssistant')),
    },
  ],
};
// 门户后台
export const ConsoleRoute = {
  path: '/console',
  component: lazy(() => import('@src/components/ui-components/Layout2/Console/ConsoleLayout')),
  children: [
    // 个人信息
    { path: 'account-indv', component: lazy(() => import('@src/routes/console/person-info')) },
    // 我的团队
    {
      path: 'team-indv/*',
      component: lazy(() => import('@src/routes/console/team-intro/TeamIntro')),
    },
    // 批量导入
    {
      path: 'team-indv/import',
      component: lazy(() => import('@src/routes/console/team-intro/TeamImport')),
    },
    // 人员管理
    { path: 'person', component: lazy(() => import('@src/routes/console/team-person/TeamPerson')) },
    // 团队认证
    { path: 'team-verify', component: lazy(() => import('@src/routes/console/team-group-auth/TeamGroupAuth')) },
    // 资源
    { path: 'programs', component: lazy(() => import('@src/routes/console/resource-console/resource-list')) },
    {
      path: 'programs/view/:id',
      component: lazy(() => import('@src/routes/console/resource-console/resource-detail')),
    },
    {
      path: 'programs/publish/:id',
      component: lazy(() => import('@src/routes/console/resource-console/resource-publish')),
    },
    // 申请资源
    { path: 'applied-programs', component: lazy(() => import('@src/routes/console/apply-console/resource-apply')) },
    // 报名管理
    { path: 'manage-applications', component: lazy(() => import('@src/routes/console/apply-console/apply-list')) },
    {
      path: 'manage-applications/applied-edit',
      component: lazy(() => import('@src/routes/console/apply-console/apply-edit')),
    },
    {
      path: 'manage-applications/applied-recycle',
      component: lazy(() => import('@src/routes/console/apply-console/apply-recycle')),
    },
    // 团队及产品
    {
      path: 'contribute-team',
      component: lazy(() => import('@src/routes/console/innovation-library/InnovationIndex')),
    },
    {
      path: 'contribute-team/detail/*',
      component: lazy(() => import('@src/routes/console/innovation-library/TeamDetail/TeamDetailIndexCopy')),
    },
    // 图谱
    { path: 'knowledge', component: lazy(() => import('@src/routes/console/innovation/innovation-list')) },
    {
      path: 'knowledge/detail/:id',
      component: lazy(() => import('@src/routes/console/innovation/innovation-publish')),
    },
    // 我的收藏
    { path: 'favorites', component: lazy(() => import('@src/routes/console/favorite')) },
    // 站内信
    { path: 'inmail', component: lazy(() => import('@src/routes/console/message/message-list')) },
    { path: 'inmail/detail/:id', component: lazy(() => import('@src/routes/console/message/message-detail')) },
    // 定向推送
    { path: 'broadcast', component: lazy(() => import('@src/routes/console/message/message-push')) },
    { path: 'broadcast/detail/:id', component: lazy(() => import('@src/routes/console/message/message-push-detail')) },
    // 订阅接收管理
    { path: 'manage-subscription', component: lazy(() => import('@src/routes/console/message/message-manage')) },
    { path: 'message-manage', component: lazy(() => import('@src/routes/console/message/message-manage')) },

    { path: 'ai-chat/detail/:id', component: lazy(() => import('@src/routes/console/ai-assistant/ai-chat-detail')) },
    { path: 'ai-chat', component: lazy(() => import('@src/routes/console/ai-assistant/ai-chat')) },
    { path: 'ai-collection', component: lazy(() => import('@src/routes/console/ai-assistant/ai-collection')) },
    {
      path: 'ai-feedback/detail/:id',
      component: lazy(() => import('@src/routes/console/ai-assistant/ai-feedback-detail')),
    },
    { path: 'ai-feedback/log', component: lazy(() => import('@src/routes/console/ai-assistant/ai-feedback-log')) },
    { path: 'ai-feedback', component: lazy(() => import('@src/routes/console/ai-assistant/ai-feedback')) },
  ],
};

// 其他单独页面 没有header 头的页面
export const SINGLE_PAGE = [
  // 登陆
  { path: '/login', component: lazy(() => import('@src/routes/single-page/Account/Login')) },
  // 注册
  { path: '/signup', component: lazy(() => import('@src/routes/single-page/Account/SignUp')) },
  // 找回密码
  { path: '/findpwd', component: lazy(() => import('@src/routes/single-page/Account/FindPwd')) },
  // pdf view 兼容
  { path: '/pdfview', component: lazy(() => import('@src/routes/single-page/PdfView')) },
  { path: '/third-signup/wechat', component: lazy(() => import('@src/routes/single-page/Account/SignUp')) },
  { path: '/third-login/wechat', component: lazy(() => import('@src/routes/single-page/Account/Login')) },
  { path: '/third-login/google', component: lazy(() => import('@src/routes/single-page/Account/Third/Google')) },
  {
    path: '/third-login/makeable',
    component: lazy(() => import('@src/routes/single-page/LoginThird/components/Makeable/Makeable')),
  },
  { path: '/third-login', component: lazy(() => import('@src/routes/single-page/LoginThird/LoginThird')) },
  { path: '/oauth/authorize', component: lazy(() => import('@src/routes/single-page/ThirdAuthorize/ThirdAuthorize')) },
  { path: '/external-link', component: lazy(() => import('@src/routes/single-page/ExternalLink/ExternalLink')) },
  { path: '/logout', component: lazy(() => import('@src/routes/single-page/Logout/Logout')) },
  { path: '/apply', component: lazy(() => import('@src/routes/console/apply-console/apply-iframe')) },
  { path: '/apply-carbon', component: lazy(() => import('@src/routes/console/apply-console/apply-carbonx-iframe')) },

  // 特殊资源 seeclimateweek2024
  {
    path: '/@seeclimateweek2024',
    component: lazy(() => import('@src/routes/single-page/SeeClimateWeek2024/SeeClimateWeek2024')),
  },
  // 多租户
  {
    path: '/ai',
    component: lazy(() => import('@src/components/ui-components/Layout2/ContentLayout')),
    children: [{ path: ':id', component: lazy(() => import('@src/routes/single-page/AITenant')) }],
  },
  // 如果用户使用了自定义路由@开头，导致没有匹配到上面任意路由，就会进入此路由
  {
    path: '/',
    component: lazy(() => import('@src/components/ui-components/Layout2/ContentLayout')),
    children: [
      { path: '/:id', component: lazy(() => import('@src/routes/single-page/CustomResOrTeam/CustomResOrTeam')) },
    ],
  },
];
